import React from 'react'

function Fnf() {
  return (
    <div style={{marginTop:'5rem',display:'flex',justifyContent:'center',alignItems:'center',height:'80vh'}}>
        <div>
            <p>404</p>
        </div>
    </div>
  )
}

export default Fnf