import review from "./components/review";
import dummy from "./images/dummy.jpg"

export const pageData = {
    chennai:{
        title:"Chennai",
        reviews:[
            {reviewimage:dummy,name:"Priya S",Location: "Chennai",Review: "Switching to solar with Getin Solar has been life-changing! The installation process was seamless, and my electricity bills have reduced drastically. Truly a step towards sustainability!"},
            {reviewimage:dummy,name:"Ramesh K.",Location: "Chennai",Review: "Their team explained everything clearly, from subsidies to maintenance. Excellent customer service and quick response time! "},
            {reviewimage:dummy,name:" Lakshmi M.",Location: "Chennai",Review: "I was hesitant at first, but after seeing the benefits, I'm so glad I chose Getin Solar. Highly recommended for anyone considering solar energy"},
          ],
          faqs: [
            { 
              question: "What is the cost of installing solar panels in Chennai?", 
              answer: "The cost varies based on the system size and type. On average, a 3 kW solar system costs between ₹1.5 lakhs to ₹2 lakhs, including installation." 
            },
            { 
              question: "Are government subsidies available for solar installations in Chennai?", 
              answer: "Yes, the government offers subsidies of up to 40% for residential installations under the rooftop solar scheme, making solar more affordable." 
            },
            { 
              question: "How much can I save on electricity bills with solar panels?", 
              answer: "Homeowners can save up to 90% on their monthly electricity bills, depending on their energy consumption and system size." 
            },
            { 
              question: "Do solar panels work during Chennai's monsoon season?", 
              answer: "Yes, solar panels work on cloudy days, though their efficiency may decrease slightly due to reduced sunlight." 
            },
            { 
              question: "What maintenance is required for solar panels?", 
              answer: "Solar panels require minimal maintenance, such as occasional cleaning of dust and debris, and annual professional inspections." 
            },
            { 
              question: "How long do solar panels last?", 
              answer: "Solar panels typically last 25–30 years with proper care. Most systems come with warranties covering performance for at least 10–15 years." 
            },
            { 
              question: "Can I sell excess power generated by my solar panels?", 
              answer: "Yes, through net metering, you can sell surplus energy back to the Tamil Nadu Electricity Board, reducing your overall costs." 
            },
            { 
              question: "Do I need a battery for my solar system?", 
              answer: "Batteries are optional but useful for storing power during outages. Grid-connected systems can function without batteries." 
            },
            { 
              question: "How can I apply for a solar subsidy in Chennai?", 
              answer: "Visit the government’s solar subsidy website, register with your details, and upload the required documents to complete the process." 
            },
            { 
              question: "Does switching to solar increase property value?", 
              answer: "Yes, homes with solar installations often attract higher property valuations, as they appeal to energy-conscious buyers and promise long-term savings." 
            }
          ]
    },
    bengaluru:{
        title:"Bengaluru",
        reviews:[
            {reviewimage:dummy,name:"Vinod P.",Location: "Bengaluru",Review: "Their solar panels are of top-notch quality. I’ve already started noticing the savings. Thanks to the professional team for the hassle-free installation."},
            {reviewimage:dummy,name:"Gayathri V.",Location: "Bengalurui",Review: "Solar panels from Getin Solar are the best investment I’ve made. They guided me through the subsidy process and helped me make the right choice. "},
            {reviewimage:dummy,name:" Arun R.",Location: "Bengaluru",Review: "I am proud to say I’m contributing to a greener planet. Thank you, Getin Solar, for making it possible in Bengaluru"},
          ],
          faqs: [
            { 
              question: "What is the cost of installing solar panels in Bengaluru?", 
              answer: "The cost of installing solar panels in Bengaluru ranges from ₹1.5 lakhs to ₹2 lakhs for a 3 kW system, depending on the quality and size of the system." 
            },
            { 
              question: "Are there government subsidies available for solar installation in Bengaluru?", 
              answer: "Yes, under the Indian government’s rooftop solar scheme, you can receive a subsidy of up to 40% for residential solar installations." 
            },
            { 
              question: "How much can I save on electricity bills with solar panels in Bengaluru?", 
              answer: "Solar panels can save you up to 90% of your monthly electricity bills, with savings varying based on your usage, system size, and location." 
            },
            { 
              question: "Do solar panels work in Bengaluru’s monsoon season?", 
              answer: "Yes, solar panels continue to work during monsoon season, though energy production may be lower on cloudy days." 
            },
            { 
              question: "What maintenance is required for solar panels in Bengaluru?", 
              answer: "Solar panels require minimal maintenance, such as cleaning the surface from dust and debris, and occasional inspections by professionals." 
            },
            { 
              question: "How long do solar panels last in Bengaluru?", 
              answer: "Most solar panels last 25–30 years with proper care and maintenance, offering long-term efficiency and reliability." 
            },
            { 
              question: "Can I sell excess power generated by my solar panels in Bengaluru?", 
              answer: "Yes, you can sell excess electricity to the Karnataka Power Transmission Corporation Ltd (KPTCL) through net metering." 
            },
            { 
              question: "Do I need to install a battery with my solar system in Bengaluru?", 
              answer: "Batteries are optional, but they are useful for storing power during the day for use at night or during power outages." 
            },
            { 
              question: "How can I apply for a solar subsidy in Bengaluru?", 
              answer: "To apply for a solar subsidy, visit the official government website, complete the registration process, and submit the necessary documents." 
            },
            { 
              question: "Will installing solar panels increase my property value in Bengaluru?", 
              answer: "Yes, homes with solar panels tend to have higher resale values, as they provide long-term savings on electricity bills and are seen as eco-friendly." 
            }
          ]
    },
    coimbatore:{
        title:"Coimbatore",
        reviews:[
            {reviewimage:dummy,name:"Kavitha J.",Location: "Coimbatore",Review: "The entire process was smooth and efficient. The team was professional and completed the installation on time. Loving the savings!"},
            {reviewimage:dummy,name:"Naveen S. ",Location: "Coimbatore",Review: "I was impressed by their attention to detail and the high-quality panels they provided. Excellent customer support too. "},
            {reviewimage:dummy,name:" Meena L. ",Location: "Coimbatore",Review: "Switching to solar has been the best decision for my home. Getin Solar’s team was very supportive throughout the process."},
          ],
          faqs: [
            { 
              question: "How much do solar panels cost in Coimbatore?", 
              answer: "In Coimbatore, the price of solar panel installations starts around ₹1.2 lakh for a 3 kW system. The final cost will depend on the quality of the system and the installer." 
            },
            { 
              question: "Can I get a subsidy for installing solar panels in Coimbatore?", 
              answer: "Yes, the Indian Government provides subsidies on solar panel installations for residential properties, covering up to 40% of the cost." 
            },
            { 
              question: "How much can solar panels reduce my electricity bill in Coimbatore?", 
              answer: "In Coimbatore, solar panels can reduce your electricity bill by up to 90%, depending on the system size and energy consumption." 
            },
            { 
              question: "Do solar panels work in Coimbatore’s humid climate?", 
              answer: "Yes, solar panels work effectively in Coimbatore’s humid climate as long as they are properly maintained and cleaned regularly to avoid dust accumulation." 
            },
            { 
              question: "What kind of maintenance is required for solar panels in Coimbatore?", 
              answer: "Solar panels in Coimbatore need minimal maintenance, typically just cleaning the panels and having the system checked once a year." 
            },
            { 
              question: "What is the lifespan of solar panels in Coimbatore?", 
              answer: "Solar panels in Coimbatore have a typical lifespan of 25-30 years, providing long-term savings and sustainability." 
            },
            { 
              question: "How does net metering work in Coimbatore?", 
              answer: "With net metering, any excess energy your solar panels generate can be fed back into the grid, allowing you to receive credits for future energy use." 
            },
            { 
              question: "Are battery backups necessary for solar systems in Coimbatore?", 
              answer: "Battery backups are not mandatory but are recommended if you want to store excess energy for use during the night or in case of power cuts." 
            },
            { 
              question: "Can I apply for solar subsidies online in Coimbatore?", 
              answer: "Yes, you can apply for solar subsidies by visiting the official government portal and filling in the necessary details." 
            },
            { 
              question: "Will installing solar panels increase my home’s value in Coimbatore?", 
              answer: "Yes, installing solar panels can increase the resale value of your home, as many buyers look for homes with energy-efficient features." 
            }
          ]
    },
    madurai:{
        title:"Madurai",
        reviews:[
            {reviewimage:dummy,name:"Suresh A.",Location: "Madurai",Review: "Getin Solar has exceeded my expectations. The panels are efficient, and the team was incredibly professional."},
            {reviewimage:dummy,name:"Anitha K.",Location: "Madurai",Review: "I’m thrilled with the service provided by Getin Solar. They made the entire process hassle-free and affordable. "},
            {reviewimage:dummy,name:"Rajesh M.",Location: "Madurai",Review: "Their commitment to quality and customer satisfaction is remarkable. I highly recommend them to anyone in Madurai."},
          ],
          faqs: [
            { 
              question: "What is the average cost of solar panel installation in Madurai?", 
              answer: "The cost of installing a 3 kW solar panel system in Madurai is typically ₹1.2 lakh to ₹1.5 lakh." 
            },
            { 
              question: "Can I apply for a solar panel subsidy in Madurai?", 
              answer: "Yes, you can avail of the subsidy under the government’s solar rooftop scheme, which covers up to 40% of the installation cost." 
            },
            { 
              question: "How much can I save on my electricity bill by switching to solar in Madurai?", 
              answer: "Switching to solar in Madurai can reduce your electricity bill by up to 85%, depending on your usage and system size." 
            },
            { 
              question: "Do solar panels work in Madurai during the monsoon?", 
              answer: "Yes, solar panels work even during the monsoon season, although there may be a slight decrease in performance on cloudy days." 
            },
            { 
              question: "How long do solar panels last in Madurai?", 
              answer: "Solar panels in Madurai last around 25-30 years, providing significant long-term energy savings." 
            },
            { 
              question: "Is it necessary to install battery storage with solar systems in Madurai?", 
              answer: "Battery storage is optional, but it can help you store excess energy generated during the day for use at night or during power outages." 
            },
            { 
              question: "What maintenance is required for solar panels in Madurai?", 
              answer: "Minimal maintenance is required for solar panels in Madurai, mostly cleaning the panels and annual checks." 
            },
            { 
              question: "Can I sell excess power to the grid in Madurai?", 
              answer: "Yes, you can sell excess solar power to the Tamil Nadu Electricity Board (TNEB) through the net metering system." 
            },
            { 
              question: "How do I apply for a solar subsidy in Madurai?", 
              answer: "You can apply for a solar subsidy by visiting the official government portal and submitting the necessary documentation." 
            },
            { 
              question: "Do solar panels increase property value in Madurai?", 
              answer: "Yes, homes with solar panels can see an increase in value, as they offer long-term savings on electricity and attract eco-conscious buyers." 
            }
          ]
    },
    virudhunagar:{
        title:"Virudhunagar",
        reviews:[
            {reviewimage:dummy,name:"Janani T",Location: "Virudhunagar",Review: "Getin Solar provided a quick and efficient solution for my energy needs. They handled everything with care and professionalism."},
            {reviewimage:dummy,name:"Hariharan D.",Location: "Virudhunagar",Review: "The subsidy options were explained thoroughly, and the installation was completed on time. Very happy with the results!"},
            {reviewimage:dummy,name:"Shanthi R. ",Location: "Virudhunagar",Review: "Thanks to Getin Solar, I’ve seen a significant drop in my energy bills. Their team is reliable and knowledgeable. "},
          ],
          faqs: [
            { 
              question: "What is the cost of installing solar panels in Virudhunagar?", 
              answer: "In Virudhunagar, the cost of installing solar panels typically starts at ₹1.1 lakh for a 3 kW system, depending on the quality of the panels and the installer." 
            },
            { 
              question: "Is there a government subsidy available for solar installation in Virudhunagar?", 
              answer: "Yes, the Government of India offers subsidies for residential solar systems in Virudhunagar, covering up to 40% of the installation cost." 
            },
            { 
              question: "How much can I save on electricity bills by using solar energy in Virudhunagar?", 
              answer: "With solar panels in Virudhunagar, you can reduce your electricity bill by up to 90%, depending on your energy consumption and the system size." 
            },
            { 
              question: "Do solar panels perform efficiently during the monsoon in Virudhunagar?", 
              answer: "Solar panels in Virudhunagar continue to generate electricity during the monsoon season, although their efficiency may be slightly reduced on cloudy days." 
            },
            { 
              question: "What is the lifespan of solar panels in Virudhunagar?", 
              answer: "Solar panels in Virudhunagar last 25-30 years with proper maintenance, providing long-term energy savings and sustainability." 
            },
            { 
              question: "Do I need battery storage for my solar system in Virudhunagar?", 
              answer: "Battery storage is optional but highly recommended if you want to store excess energy for nighttime use or during power outages." 
            },
            { 
              question: "How often do solar panels require maintenance in Virudhunagar?", 
              answer: "Solar panels in Virudhunagar require minimal maintenance, which mainly includes periodic cleaning and an annual system check-up." 
            },
            { 
              question: "Can I sell the excess solar power I generate in Virudhunagar?", 
              answer: "Yes, through the net metering system, excess solar power generated can be sent back to the grid and you can receive credits for future energy usage." 
            },
            { 
              question: "How can I apply for a solar subsidy in Virudhunagar?", 
              answer: "You can apply for a solar subsidy by visiting the official government website for solar installations and submitting the required documents." 
            },
            { 
              question: "Will installing solar panels increase the value of my property in Virudhunagar?", 
              answer: "Yes, installing solar panels can increase the value of your property in Virudhunagar, as buyers find solar-equipped homes more attractive due to the long-term energy savings." 
            }
          ]
          
    },
    thirunelveli:{
        title:"Thirunelveli",
        reviews:[
            {reviewimage:dummy,name:"Vignesh V.",Location: "Thirunelveli",Review: "The solar panels are performing beyond my expectations. Great service and a sustainable solution for our energy needs."},
            {reviewimage:dummy,name:"Deepa N.",Location: "Thirunelveli",Review: "I couldn’t be happier with the choice to go solar. Getin Solar made the entire process stress-free and affordable. "},
            {reviewimage:dummy,name:"Karthik P. ",Location: "Thirunelveli",Review: "Highly recommend their services! The team is skilled, and the savings on my bills are noticeable. "},
          ],
          faqs: [
            { 
              question: "How much can I save on electricity bills with solar panels in Thirunelveli?", 
              answer: "In Thirunelveli, solar panels can reduce electricity bills by up to 80-90%, depending on the system size and your energy consumption." 
            },
            { 
              question: "Are there government subsidies for solar panel installations in Thirunelveli?", 
              answer: "Yes, the Government of India offers subsidies for solar panel installations in Thirunelveli, with subsidies covering up to 40% of the total installation cost for residential setups." 
            },
            { 
              question: "What are the maintenance costs of solar panels in Thirunelveli?", 
              answer: "Solar panels in Thirunelveli require minimal maintenance. Typically, you’ll only need to clean them occasionally, and the maintenance costs are quite low, usually covered under warranty." 
            },
            { 
              question: "How long do solar panels last in Thirunelveli?", 
              answer: "Solar panels in Thirunelveli are designed to last for 25-30 years with proper maintenance, making them a reliable long-term investment." 
            },
            { 
              question: "Do I need battery storage for my solar system in Thirunelveli?", 
              answer: "Battery storage is optional, but it allows you to store excess energy generated during the day to use at night or during power outages, providing more energy independence." 
            },
            { 
              question: "Can I install solar panels on my roof in Thirunelveli?", 
              answer: "Yes, as long as you have adequate roof space and exposure to sunlight, you can install solar panels in Thirunelveli. A site assessment can determine the best configuration for your home." 
            },
            { 
              question: "What is the return on investment (ROI) for solar panels in Thirunelveli?", 
              answer: "The ROI on solar panels in Thirunelveli is typically 3-5 years, after which you can enjoy free electricity, making it a highly cost-effective option." 
            },
            { 
              question: "Can I use solar power during power outages in Thirunelveli?", 
              answer: "If you have a battery storage system installed along with your solar panels, you can continue using solar power during outages, providing energy reliability." 
            },
            { 
              question: "Are solar panels affected by the weather in Thirunelveli?", 
              answer: "Solar panels in Thirunelveli are designed to perform well even during the monsoon season. While cloud cover may reduce efficiency, they continue to generate power even in lower light conditions." 
            },
            { 
              question: "How do I apply for a solar subsidy in Thirunelveli?", 
              answer: "To apply for a solar subsidy in Thirunelveli, visit the official solar subsidy website, fill out the application form, and submit the necessary documents like your Aadhaar number, address proof, and bank details." 
            }
          ]
          
    },
    kovilpatti:{
        title:"KovilPatti",
        reviews:[
            {reviewimage:dummy,name:"Swetha R.",Location: "KovilPatti",Review: "The panels are of excellent quality, and the team ensured timely installation. Getin Solar is the best in the business!"},
            {reviewimage:dummy,name:"Manoj K.",Location: "KovilPatti",Review: "Their guidance and support through the process were commendable. I’m proud to have made the switch to solar energy."},
            {reviewimage:dummy,name:"Padma V.",Location: "KovilPatti",Review: "With their help, I’m saving money and helping the environment. Thank you, Getin Solar, for your exceptional service."},
          ],
          faqs: [
            { 
              question: "What are the benefits of installing solar panels in Kovilpatti?", 
              answer: "Installing solar panels in Kovilpatti can help reduce electricity bills significantly, lower carbon footprints, and offer energy independence, especially in a region with abundant sunlight." 
            },
            { 
              question: "How much money can I save with solar panels in Kovilpatti?", 
              answer: "In Kovilpatti, solar panels can help you save up to 70-80% on your monthly electricity bills, depending on the size of the system and your energy usage." 
            },
            { 
              question: "Is it possible to get a solar subsidy in Kovilpatti?", 
              answer: "Yes, solar panel installations in Kovilpatti are eligible for subsidies from the Indian government. The subsidy covers a portion of the installation costs, making solar power more affordable." 
            },
            { 
              question: "How long does a solar system last in Kovilpatti?", 
              answer: "Solar systems in Kovilpatti are built to last between 25 to 30 years, with minimal maintenance required. Regular cleaning and annual inspections are typically enough to keep them functioning efficiently." 
            },
            { 
              question: "Do solar panels require a lot of maintenance in Kovilpatti?", 
              answer: "No, solar panels require minimal maintenance. In Kovilpatti, occasional cleaning to remove dust and debris, along with periodic inspections, is enough to keep the system running smoothly." 
            },
            { 
              question: "Can I install solar panels on my roof in Kovilpatti?", 
              answer: "Yes, as long as your roof receives sufficient sunlight and is structurally sound, you can install solar panels in Kovilpatti. A site assessment by a professional installer can determine the best setup for your home." 
            },
            { 
              question: "What are the financial options available for solar installations in Kovilpatti?", 
              answer: "In Kovilpatti, you can explore various financing options for solar installations, including loans, EMIs, or government schemes, to help cover the initial costs of installation." 
            },
            { 
              question: "How does the solar panel installation process work in Kovilpatti?", 
              answer: "The installation process includes a site survey, system design, installation of the panels, and grid connection. Most installations are completed within a few days, depending on the size of the system." 
            },
            { 
              question: "What happens if I produce more energy than I use in Kovilpatti?", 
              answer: "In Kovilpatti, any excess energy produced by your solar system can be fed back into the grid through net metering, and you can receive credit for the energy contributed." 
            },
            { 
              question: "How do I apply for the solar subsidy in Kovilpatti?", 
              answer: "To apply for the solar subsidy in Kovilpatti, you need to visit the official government solar subsidy website, submit the necessary documentation, and complete the online application process." 
            }
          ]
          
    },
    kanniyakumari:{title:"Kanniyakumari",
        reviews:[
            {reviewimage:dummy,name:"Selva N.",Location: "Chennai",Review: "SGetin Solar’s team is very professional and knowledgeable. My home is now energy-efficient, and I’m loving the benefits."},
            {reviewimage:dummy,name:"Revathi B.",Location: "Chennai",Review: "They made the process simple and transparent. From subsidy to installation, everything was handled seamlessly. "},
            {reviewimage:dummy,name:" Balaji S.",Location: "Chennai",Review: "The solar system has been working perfectly, and I’m seeing great savings. Getin Solar is the way to go! "},
          ],
          faqs: [
            { 
              question: "How much can I save on electricity bills by installing solar panels in Kanniyakumari?", 
              answer: "In Kanniyakumari, solar panels can help you save up to 75-80% on your monthly electricity bills, depending on your usage and the size of the solar system installed." 
            },
            { 
              question: "Are solar panels eligible for subsidies in Kanniyakumari?", 
              answer: "Yes, solar panel installations in Kanniyakumari are eligible for subsidies under various government schemes, which significantly reduce the upfront cost of installation." 
            },
            { 
              question: "What is the lifespan of solar panels in Kanniyakumari?", 
              answer: "Solar panels in Kanniyakumari typically last 25-30 years with proper maintenance, providing long-term benefits in terms of cost savings and energy production." 
            },
            { 
              question: "How does the solar installation process work in Kanniyakumari?", 
              answer: "The installation process includes a site survey, designing the system based on your energy needs, installation of the panels, and connecting them to the grid. It usually takes a few days to complete." 
            },
            { 
              question: "Is solar energy reliable in Kanniyakumari?", 
              answer: "Yes, Kanniyakumari, with its abundant sunlight, is an ideal location for solar energy generation, ensuring reliable energy production throughout the year." 
            },
            { 
              question: "Do I need a battery storage system for my solar panels in Kanniyakumari?", 
              answer: "While you can rely on grid power during the night or cloudy days, installing a battery storage system in Kanniyakumari allows you to store excess energy produced during the day for use at night." 
            },
            { 
              question: "What are the maintenance requirements for solar panels in Kanniyakumari?", 
              answer: "Solar panels in Kanniyakumari require minimal maintenance, mainly cleaning the panels to remove dust and debris, along with occasional checks to ensure optimal performance." 
            },
            { 
              question: "How long will it take for my solar system to pay for itself in Kanniyakumari?", 
              answer: "In Kanniyakumari, you can expect a return on investment (ROI) for your solar system in 5 to 7 years, depending on your energy usage and the size of the system." 
            },
            { 
              question: "Can solar panels increase my property value in Kanniyakumari?", 
              answer: "Yes, installing solar panels in Kanniyakumari can increase your property value, as more buyers are attracted to homes with sustainable energy solutions and reduced energy costs." 
            },
            { 
              question: "How can I apply for the solar panel subsidy in Kanniyakumari?", 
              answer: "To apply for the solar panel subsidy in Kanniyakumari, visit the official government website for solar subsidies, fill in the application form, and submit the required documents." 
            }
          ]
          
    },
}